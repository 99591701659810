.App {
  text-align: center;
  background-color:#282c3c;
}

.Header {
  background-color:#282c3c;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:1.5%;
  }
  .Logo {
    margin-top: 5%;
    max-width: 90%;
    max-height: 90%;

  }
  
.Header h1 {
  margin: 0;
}

.Main {
  background-color:#282c3c;
  height: 90vh;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  /*align-items: center;*/
  /*flex-wrap: nowrap;*/
}

.Main-Container {
  background-color:#282c3c;
  height: 35%;
  width: 75%;
  border-radius: 15px;
  margin: 5% auto;
  padding: 5%;
}

.urlDownloadLink {
  color: purple;
  text-decoration: underline;
  font-size: 1.5em;
}
